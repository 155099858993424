<template>
  <div class="show-file-content">
    <div class="file-content-header">
      <div class="data-file">
        <Pdf v-if="['pdf'].includes(form.type)" class="pdf-icon" />
        <Jpg
          v-else-if="['jpg', 'jpeg', 'jps'].includes(form.type)"
          class="jpg-icon"
        />
        <Png v-else-if="['png'].includes(form.type)" class="png-icon" />
        <div>
          <div class="file-name-wrapper">
            <div
              class="file-name"
              :contenteditable="true"
              @blur="e => changeName(index, e.target.innerText)"
            >
              {{ form.custom_name }}
            </div>
          </div>
          <span class="edit-label">(Clique no nome para editar)</span>
        </div>
      </div>

      <div class="right-options">
        <div v-if="isLaudable(index)" class="laudable-check">
          <span>solicitar laudo</span>
          <Check v-model="form.laudable" />
        </div>

        <Close class="close-icon" @click="removeFile(index)" v-if="!sent" />
      </div>
    </div>

    <div class="file-type" v-if="!isGroupedFiles">
      <input
        class="input-file-type"
        type="radio"
        :name="`type_${index}`"
        :id="`intern_${index}`"
        value="intern"
        v-model="form.type_id"
        checked
      />
      <p>Realizado internamente</p>

      <input
        class="input-file-type"
        type="radio"
        :name="`type_${index}`"
        :id="`intern_${index}`"
        value="extern"
        v-model="form.type_id"
      />
      <p>Em outra instituição</p>
    </div>

    <div class="doc-type" v-if="showDistinctType">
      <b-form-checkbox
        class="checkbox"
        v-model="form.distinct_type"
        name="distinct_type"
        value="doc"
      >
        Documento
      </b-form-checkbox>
      <b-form-checkbox
        class="checkbox"
        v-model="form.distinct_type"
        name="distinct_type"
        value="exam"
      >
        Exame
      </b-form-checkbox>
    </div>

    <div v-if="form.laudable" class="laudable-forms">
      <b-form-group id="field_exam">
        <label for="field_exam">Exame</label>
        <multiselect
          :value="selectedExam"
          track-by="id"
          label="label"
          :options="procedures"
          :option-height="40"
          :searchable="true"
          :close-on-select="true"
          :showLabels="false"
          :multiple="false"
          placeholder="Selecione o exame"
          class="with-border"
          @select="value => selectExam(index, value)"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions"> Nenhuma opção </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </b-form-group>
      <b-form-group id="responsibles">
        <label for="responsibles">
          Responsável(is) pelo laudo
        </label>
        <multiselect
          track-by="id"
          label="name"
          v-model="form.selectedResponsibles"
          :options="form.responsibles"
          :option-height="40"
          :searchable="true"
          :close-on-select="false"
          :showLabels="false"
          :multiple="true"
          :disabled="!form.procedure_id"
          placeholder="Selecione os(as) responsáveis pelo laudo deste exame"
          class="with-border multiple"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="selection" slot-scope="{ values }">
            <div class="treatment">
              {{ values.map(el => el.name).join(', ') }}
            </div>
          </template>
          <template slot="noOptions"> Nenhuma opção </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </b-form-group>
    </div>

    <b-progress
      v-if="form.progress"
      class="progress"
      :max="100"
      show-progress
      :animated="form.progress !== 100"
      :variant="!form.error ? 'primary' : 'danger'"
    >
      <b-progress-bar :value="form.progress">
        <span>
          <strong>{{ form.progress }}%</strong>
        </span>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
export default {
  name: 'FileItem',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    sent: {
      type: Boolean,
      default: false
    },
    responsibles: Array,
    procedures: Array,
    removeFile: Function,
    isLaudable: Function,
    changeName: Function,
    getResponsables: Function,
    showDistinctType: Boolean,
    isGroupedFiles: Boolean,
    type_all: String,
  },
  components: {
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Jpg: () => import('@/assets/icons/jpg.svg'),
    Png: () => import('@/assets/icons/png.svg'),
    Check: () => import('@/components/General/Check'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg')
  },
  methods: {
    selectExam(index, value) {
      this.form.procedure_id = value.id
      this.getResponsables(index, value.id)
    }
  },
  computed: {
    selectedExam() {
      return this.procedures.find(
        procedure => procedure.id === this.form.procedure_id
      )
    }
  },
  watch: {
    type_all: {
      handler(value) {
          if(this.isGroupedFiles){
            this.form.type_id = value;
          }
        },
        immediate: true,
        deep: true
    },
  }
}
</script>

<style lang="scss">
.show-file-content {
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 10px;

  .file-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .data-file {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .pdf-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      .jpg-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      .png-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      #data-file-text {
        margin-left: 16px;
        width: 40ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close-icon {
      height: 24px;
      width: 24px;
      fill: var(--neutral-500);
    }
  }

  .laudable-forms {
    padding-top: 15px;
  }

  .file-name-wrapper {
    cursor: default !important;
  }
}
</style>
